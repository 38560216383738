import axios from "axios";

export const RESULTS_FETCH_BEGIN = "RESULT_FETCH_BEGIN";
export const RESULTS_FETCH_DONE = "RESULT_FETCH_DONE";
export const RESULTS_FETCH_FAILURE = "RESULTS_FETCH_FAILURE"
export const RESULT_SELECT = "RESULT_SELECT"
export const RESULT_UPDATE = "RESULT_UPDATE"
export const RESULT_UNSELECT = "RESULT_UNSELECT"
export const FILE_FETCH = "FILE_FETCH";

// const img = new window.Image()

// img.onload = ({target: img}) => {
//     console.log("\n\n**********\n")
//     console.log( 500/img.naturalWidth," : ",500/img.naturalHeight)
//     console.log("\n\n**********\n")
//   }

// Get user scan History
export const getScanHistory = () => async dispatch => {
    try {
        dispatch({
            type: RESULTS_FETCH_BEGIN
        })
        const res = await axios.get('/result/getHistoryData')
        console.log("response history",res.data);
        dispatch({
            type: RESULTS_FETCH_DONE,
            payload: res.data
        })
    } catch (error) {
        console.log("error at axios",error);
        dispatch({
            type: RESULTS_FETCH_FAILURE,
            payload: error.message
        })
    }
}

export const updateOldResult = (newData) => dispatch => {
    // console.log("New data")
    dispatch({
        type: RESULT_UPDATE,
        payload: newData
    })
}

// To be implemented once result is fetched based on id
export const loadResult = (id) => async dispatch => {
    try {
        dispatch({
            type: RESULTS_FETCH_BEGIN
        })
        const res = await axios.get('/result/getHistoryData')
        dispatch({
            type: RESULTS_FETCH_DONE,
            payload: res.data
        })
        console.log("\nA\n\n",res.data)
        let selectedResult = res.data.filter(item => item.iD === parseInt(id))[0]
        //console.log("selectedResult",selectedResult);
        // console.log('\n\n Selected result :\n', selectedResult,"\n\n")
        let body = {fileName: selectedResult.scannedResultImageName}
        const imgUrl = await axios.post("/result/getscannedImage", body)
        //console.log("imgUrl",imgUrl);
        // img.src = imgUrl
        dispatch({
            type: RESULT_SELECT,
            payload: selectedResult
        })
        dispatch({
            type: FILE_FETCH,
            payload: imgUrl.data
        })
    } catch (error) {
        console.log(error);
        dispatch({
            type: RESULTS_FETCH_FAILURE,
            payload: error
        })
    }
}

// Get user uploaded document SaSS Url
export const getDocument = (fileName) => async dispatch => {
    try {
        let body = {fileName}
        const res = await axios.post("/result/getscannedImage", body)
        dispatch({
            type: FILE_FETCH,
            payload: res.data
        })
    } catch (error) {
        console.log(error)
    }
}