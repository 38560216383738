import { combineReducers } from "redux";
import auth from "./auth";
import marketplace from './azure'
import template from './template'
import scan from './scan'
import result from './result'
import changePasswordReducer from '../reducers/common/ChangePasswordReducer';
import forgotPasswordReducer from '../reducers/common/ForgotPasswordReducer';
import apiKeyReducer from "./apikey";

export default combineReducers({
  auth,
  marketplace,
  template,
  scan,
  result,
  changePasswordReducer,
  forgotPasswordReducer,
  apiKeyReducer
});
