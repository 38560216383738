import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../actions/auth";
import Event from "../Tracking";

const Navbar = ({ auth: { isAuthenticated }, logout }) => {
  const [displayMenu, setDisplayMenu] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setDisplayMenu(!displayMenu);
  };

  const authLinks = (
    <ul>
      {/* <li
        onClick={(e) => {
          handleClick(e);
          Event("NavbarDropdown_Dashboard", "Dashboard clicked");
        }}
      >
        <Link to="/dashboard">Dashboard</Link>
      </li> */}

      <li
        onClick={(e) => {
          handleClick(e);
          Event("NavbarDropdown_Scan", "Scan clicked");
        }}
      >
        <Link to="/ocr">Scan</Link>
      </li>
      <li
        onClick={(e) => {
          handleClick(e);
          Event("NavbarDropdown_History", "History clicked");
        }}
      >
        <Link to="/results">History</Link>
      </li>
      <li>
        <Link to="/changepassword">Change Password</Link>
      </li>
      <li>
      <Link to="/ApiKey">API Key</Link>
    </li>
      <li
        onClick={
          (handleClick, () => Event("NavbarDropdown_Logout", "Logout clicked"))
        }
      >
        <a onClick={logout} href="/login">
          Logout
        </a>
      </li>
    </ul>
  );

  const guestLinks = (
    <ul>
      <li
        onClick={(e) => {
          handleClick(e);
          Event("home_login", "Entered Login Page", "/login");
        }}
      >
        <Link to="/login">Login</Link>
      </li>
    </ul>
  );

  return (
    <div className="logodiv">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="NuOcrlogo">
              <img src="images/NuOcr-255.png" alt="" />
            </div>
          </div>
          <div className="col-md-8">
            <div className="headermenu">
              <ul>
                <li onClick={handleClick}>
                  <Link to="#">
                    <img src="images/signs.svg" alt="" />
                  </Link>
                </li>
                <li onClick={() => Event("Navbar_Home", "Home clicked")}>
                  <Link to="/">Home</Link>
                </li>
              </ul>
              {displayMenu ? (
                <div className="topnavdiv">
                  <div className="topnavmenu">
                    {isAuthenticated ? authLinks : guestLinks}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(Navbar);
