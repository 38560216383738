import axios from 'axios';

export const FORGOT_PASSWORD_BEGIN = 'FORGOT_PASSWORD_BEGIN';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const forgotPassword = (data) => async dispatch => {
    console.log("data from component in action",data)
    try {
        console.log("testing in action")
        dispatch({
            type: FORGOT_PASSWORD_BEGIN
        })
        console.log("data in try block",data)
        // const res = await axios.post('/forgotpassword', data);
        const res = await axios.post('/forgotpassword', {data});
        console.log("response value in action:", res)
        dispatch({
            type: FORGOT_PASSWORD_SUCCESS,
            payload: res.data
        })
    } catch (err) {
        console.log(err)
        dispatch({
            type: FORGOT_PASSWORD_FAILURE,
            payload: err.response
        })
    }
}