import {
  USER_API_KEY_GENERATE_BEGIN,
  USER_API_KEY_GENERATE_FAILED,
  USER_API_KEY_GENERATE_SUCCESS,
  USER_API_KEY_REMOVE_BEGIN,
  USER_API_KEY_REMOVE_FAILED,
  USER_API_KEY_REMOVE_SUCCESS,
} from "../actions/types";

const initialState = {
  key: undefined,
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER_API_KEY_GENERATE_BEGIN:
    case USER_API_KEY_REMOVE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USER_API_KEY_GENERATE_SUCCESS:
      return {
        ...state,
        key: payload,
        loading: false,
      };
    case USER_API_KEY_REMOVE_SUCCESS:
      return {
        ...state,
        key: undefined,
        loading: false,
      };
    case USER_API_KEY_REMOVE_FAILED:
    case USER_API_KEY_GENERATE_FAILED:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
};
