import React, { Component } from 'react'
import { forgotPassword } from '../../actions/common/ForgotPasswordAction'
import { connect } from "react-redux";
class ForgotPassword extends Component {

  state = {
    email: '',
    loginMessage: false,
    errorMessage: false,
    errors: {},
    message: "An email has been sent to email address,note if you didn't receive this mail,please check your junk/spam folder"
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  handleReset = (e) => {
    e.preventDefault();
    if (this.validateEmail()) {
      if (this.state.email !== '') {
        console.log(this.state.email)
        this.props.forgotPassword(this.state.email)
        // this.setState({ loginMessage: true })
      }

    }
    // console.log(this.state.email)
    // this.props.forgotPassword(this.state.email)
    // this.setState({ loginMessage: true})
  }

  validateEmail() {
    const errors = {};
    let emailIsValid = true;
    if (!this.state.email) {
      emailIsValid = false;
      errors.email = '*Please enter your email.';
    }

    if (typeof this.state.email !== 'undefined') {
      let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(this.state.email)) {
        emailIsValid = false;
        errors.email = '*Please enter valid email.';
      }
    }

    this.setState({
      errors,
    });
    return emailIsValid;
  }

  componentWillReceiveProps(props) {
    const passVal = props.forgotpassword[0]
    if (passVal !== undefined) {
      console.log("PROPS IN FORGOTPASSWORD:", passVal.RowsUpdated)
      if (passVal.RowsUpdated === 1) {
        this.setState({ loginMessage: true })
        this.setState({ errorMessage: false })
      }
    }

    if (props.error !== null) {
      if (props.error !== undefined) {
        this.setState({ errorMessage: true })
        this.setState({ loginMessage: false })
      }
    }

  }

  render() {

    // const passVal = this.props.forgotpassword[0]
    // if (passVal !== undefined) {
    //   console.log("PROPS IN FORGOTPASSWORD:", passVal.RowsUpdated)
    //   if(passVal.RowsUpdated ===1){

    //   }
    // }
    // console.log("PROPS IN FORGOTPASSWORD:", this.props.loading)
    console.log("PROPS IN FORGOTPASSWORD:", this.props.error)
    return (
      <>
        <div className="Sspace" />
        <div className="page-content">
          <div className="container">
            <div className="row">

              <div className="col-md-9">
                <div className="page-content-wrapper">
                  <div className="subheader">
                    <div className="row">
                      <div className="col-md-8">
                        <h1></h1>
                      </div>
                    </div>
                  </div>
                  <div className="Sspace" />
                  <div className="jobsort">
                    <div className="addjobdiv">
                      <div className="row">
                        <div className="col-md-12">
                          <h8>Forgot Your Password</h8>
                          <div className="reglabel">
                            Enter the email address of your account and we will send you the instruction to reset your password.
                              </div>
                        </div>
                      </div>
                      <div />
                          &nbsp;
                          <div className="col-md-7">
                        Email Address :
                              <div className="jobinputdiv">
                          <input className="jobinputtext" type="text" id="email" name="email" value={this.state.email} onChange={this.handleChange} autoComplete="off" />
                        </div>
                        <div className="errorMsg">{this.state.errors.email}</div>
                      </div>
                      <div className="Sspace" />

                      {/* {
                          this.props.forgotpassword[0]===undefined&&this.state.email!==''&&this.props.loading===false?(
                            <p className="successMsg">{this.state.message}</p>
                          ):null
                        } */}



                      <div className="buttonmaindiv">
                        {this.state.loginMessage === true ? (
                          <div className="col-md-10">
                            <div className="reglabel">Please check your email for the reset password link</div>
                          </div>
                        ) : (null)}

                        {this.state.errorMessage === true ? (
                          <div className="col-md-10">
                            <div className="reglabel">Email not found. Please enter your registered email address</div>
                          </div>
                        ) : (null)}

                        {/* <div className="col-md-10">
                          <div className="reglabel">Please check your email for the reset password link</div>
                        </div> */}
                        <div className="btndiv">


                          <button className="submitbutton" onClick={this.handleReset}>Reset Password</button>

                        </div>
                        {/* {
                          this.props.forgotpassword[0]===undefined&&this.state.email!==''&&this.props.loading===false?(
                            <p className="successMsg">{this.state.message}</p>
                          ):null
                        } */}
                        {/* {this.state.loading===true? (
                                    <>
                                      <div style={{ padding: '2px 4px' }}><img src={loading} alt="" height="15" width="15" /></div>
                                    </>
                                  ) : (
                                    
                                       (this.props.forgotpassword.RetrunText===undefined&&this.state.email!=='')?(
                                        <>
                                        <p className="successMsg">{this.state.message}</p>
                                        </>
                                      ):null */}
                        {/* {
                                      this.state.RetrunText===false?(
                                        <div className="col-md-10">
                                                                    <div className="errorMsg">Email not exist</div>
                                                                </div>
                                      ):null
                                    } */}




                        {/* )} */}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}



const mapStateToProps = (state) => ({
  forgotpassword: state.forgotPasswordReducer.forgotpassword,
  error: state.forgotPasswordReducer.error,
  loading: state.forgotPasswordReducer.loading,
});

// export default ForgotPassword

// export default connect(null, { forgotPassword })(ForgotPassword);

export default connect(mapStateToProps, { forgotPassword })(ForgotPassword);

