import React, { Component } from 'react'
import { changePassword } from '../../actions/common/ChangePasswordAction'
import { connect } from "react-redux";


class ChangePassword extends Component {

    state = {
        password: '',
        confirmpassword: '',
        currentpassword: '',
        errors: {},
        message: "New password and confirm password do not match"
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
        });
    }

    validatePassword() {
        const errors = {};
        let passwordIsValid = true;
        if (!this.state.password) {
            passwordIsValid = false;
            errors.password = '*Please enter your password.';
        }
        if (typeof this.state.password !== 'undefined') {
            if (!this.state.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)) {
                passwordIsValid = false;
                errors.password = '*Please enter valid password.';
            }
        }
        this.setState({
            errors,
        });
        return passwordIsValid;
    }

    handlePassword = (e) => {
        e.preventDefault();
        console.log("current password:", this.state.currentpassword)
        console.log("new password:", this.state.password)
        console.log("confirm password:", this.state.confirmpassword)
        // this.props.changePassword(this.state.currentpassword)

        if (this.validatePassword()) {
            if (this.state.password === this.state.confirmpassword && this.state.password !== '' && this.state.confirmpassword !== '' && this.state.currentpassword !== '') {
                this.props.changePassword({ password: this.state.password, currentPassword: this.state.currentpassword });
                // const customHistory = createBrowserHistory();
                // customHistory.push('/login')
                localStorage.clear();
                window.location.href = 'http://localhost:3000';
            }
        }

        // if(this.props.ChangePassword !== null){
        //     return <Redirect to='/login' />
        // }
    }

    // componentWillReceiveProps = (props) => { 
    //     if(props.ChangePassword){
    //         return <Redirect to='/login' />
    //     }
    // }
    render() {
        const passVal = this.props.ChangePassword[0]
        console.log("RESPONSE FROM API IN REACT:", passVal)
        return (

            <>
                <div class="Sspace"></div>
                <div class="page-content">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-9">
                                <div class="page-content-wrapper">
                                    <div class="subheader">
                                        <div class="row">
                                            <div class="col-md-8">
                                                <h1>Change Your Password</h1>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="Sspace"></div>
                                    <div class="jobsort">
                                        <div class="addjobdiv">
                                            <div class="changepassword">Please enter you current password to update</div>
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="joblabel">Current password <span>*</span></div>
                                                    <div class="jobinputdiv"><input class="jobinputtext" type="password" id="currentpassword" name="currentpassword" value={this.state.currentpassword} onChange={this.handleChange} /></div>
                                                </div>
                                                <div class="col-md-4">
                                                </div>
                                                <div class="col-md-4">
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="joblabel">New Password <span>*</span></div>
                                                    <div class="jobinputdiv"><input class="jobinputtext" type="password" id="password" name="pass1" value={this.state.password} onChange={this.handleChange} /></div>
                                                    <div className="errorMsg">{this.state.errors.password}</div>
                                                    <div class="changepasswordmust">Must use Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character</div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="joblabel">Confirm Password <span>*</span></div>
                                                    <div class="jobinputdiv"><input class="jobinputtext" type="password" id="confirmpassword" name="confirmpassword" value={this.state.confirmpassword} onChange={this.handleChange} /></div>
                                                    {
                                                        (this.state.password !== this.state.confirmpassword && this.state.password !== '' && this.state.confirmpassword !== '') ? (
                                                            <div className="errorMsg">{this.state.message}</div>
                                                        ) : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div class="Sspace"></div>
                                        <div class="buttonmaindiv">
                                            <div class="btndiv">
                                                <button class="submitbutton" onClick={this.handlePassword}>Change Password</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="Mspace"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

// export default ChangePassword

// export default connect(null, { changePassword })(ChangePassword);

const mapStateToProps = (state) => ({
    ChangePassword: state.changePasswordReducer.changepassword
});
// export default connect(mapStateToProps)(ChangePassword);

export default connect(mapStateToProps, { changePassword })(ChangePassword);