import React, {useEffect} from 'react'
import Iframe from 'react-iframe';
import { Redirect } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const notify = (text, category) => {
    if (category === "success") {
      toast.success(text);
    } else {
      toast.error(text)
    }
  };
const EditTemplate = (props) => {

    let history = useHistory();
    useEffect(() => {
        window.addEventListener('message', handleIframeTask);
    })

    const handleIframeTask = (e) => {
        
        var data = JSON.parse(localStorage.getItem('msgData'));
        localStorage.removeItem('msgData');
        if (data !== null) {
            localStorage.setItem("owntemplate", data.base64);
            notify("Saved successfully","success")
            history.push("/ocr")
            // return <Redirect to="/ocr" />
        }

    }
    return (
        <>
            <Iframe src="../EditTemplate.html"
            width="100%"
            height="500px"
            id="myId"
            className="myClassname"
            display="initial"
            position="relative"/>
        </>
    )
}

export default EditTemplate;
