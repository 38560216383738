import React, {Fragment} from 'react';
import spinner from '../../images/loading.gif'

export default function Spinner() {
    return (
        <Fragment>
           <img src={spinner} style={{ width: "200", height: "200px", margin: "auto", display: "block" }} alt="Loading...." /> 
        </Fragment>
    )
}
