import {SCAN, UPDATE_RESULT, TEMPLATE_LOADED,SAVE_PDF_BLOB} from '../actions/scan'

const initialState = {
    data:null,
    template: null,
    loading: true,
    convertedPdfData:null
}

export default (state=initialState, action) => {
    const {type, payload} = action;

    switch(type) {
        case TEMPLATE_LOADED:
            return {
                ...state,
                template: payload
            }
        case SCAN:
            console.log("payload",payload);
            return {
                ...state,
                data: payload,
                loading: false
            }
        case UPDATE_RESULT:
            return {
                ...state,
                data: payload,
                loading: false
            }
        case SAVE_PDF_BLOB:
            return {
                ...state,
                convertedPdfData: payload,
                loading: false
            }
        default:
            return state
    }
}