import {
    FORGOT_PASSWORD_BEGIN,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,
} from "../../actions/common/ForgotPasswordAction";

const initstate = {
    forgotpassword: [],
};
const forgotPasswordReducer = (state = initstate, action) => {
    // console.log("from reducer",state)
   // console.log("from reducer",action.payload)
    switch (action.type) {
        case FORGOT_PASSWORD_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                forgotpassword: action.payload,
            };

        case FORGOT_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};
export default forgotPasswordReducer;