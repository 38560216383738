import axios from 'axios'
import {
  USER_API_KEY_GENERATE_BEGIN,
  USER_API_KEY_GENERATE_SUCCESS,
  USER_API_KEY_GENERATE_FAILED,
  USER_API_KEY_REMOVE_BEGIN,
  USER_API_KEY_REMOVE_FAILED,
  USER_API_KEY_REMOVE_SUCCESS,
} from "../types";

export const generateKey = () => async dispatch => {
    try {
        dispatch({
            type: USER_API_KEY_GENERATE_BEGIN
        })
        const res = await axios.get('/api/v1/generate-key');
        console.log("response from api",res.data);
        dispatch({
            type: USER_API_KEY_GENERATE_SUCCESS,
            payload: res.data
        })
    } catch (error) {
        console.error(error);
        dispatch({
            type: USER_API_KEY_GENERATE_FAILED,
            payload: error.message
        })
    }
}

export const removeKey = key => dispatch => {
    try {
        
        // dispatch({
        //     type: USER_API_KEY_REMOVE_BEGIN
        // })
        // Api to remove key from db
        dispatch({
            type: USER_API_KEY_REMOVE_SUCCESS
        })
    } catch (error) {
        console.error(error);
        // dispatch({
        //     type: USER_API_KEY_REMOVE_FAILED,
        //     payload: error.message
        // })
    }
}