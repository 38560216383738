import axios from 'axios';
import {setBaseUrl, setAuthToken} from '../utils/setAxiosConfig'
export const USER_LOADED = "USER_LOADED"
export const USER_UNLOAD = "USER_UNLOAD"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_BEGIN = "LOGIN_BEGIN";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT = "LOGOUT";

export const loadUser = () => dispatch => {
    setBaseUrl();
    if(localStorage.token) {
        let token = JSON.parse(localStorage.getItem("token")).token;
        setAuthToken(token);
        dispatch({
            type: USER_LOADED
        })
    }
    else {
        dispatch({
            type: USER_UNLOAD
        })
    }
}

export const logout = () => dispatch => {
    dispatch({
        type: LOGOUT
    })
}

export const login = (data) => async dispatch => {
    console.log("data value in action:",data)
    try {
        dispatch({
            type: LOGIN_BEGIN
        })
        const res = await axios.post('/login', data);
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        })
    } catch (err) {
        console.log("data value in action in login failed:",err)
        dispatch({
            type: LOGIN_FAILED,
            payload: err
        })
    }
}