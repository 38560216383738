import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { Stage, Layer, Image, Rect } from "react-konva";
import CsvDownloader from "react-csv-downloader";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../layout/Spinner";
// import { updateResult } from "../../actions/scan";
import { getDocument, loadResult, updateOldResult } from "../../actions/result";


const previmg = new window.Image();

function Result({
  data: { result, resultloading, document },
  updateOldResult,
  loadResult,
  history,
  match
}) {
  const [scale,setScale] = useState({
    x_scale: null,
    y_scale: null
  })

previmg.onload = ({target: img}) => {
  //console.log("height",img.naturalHeight,"width",img.naturalWidth);
  setScale({
    x_scale: 500/img.naturalWidth,
    y_scale: 700/img.naturalHeight
  })
  
}

  const [box, setBox] = useState({
    x: null,
    y: null,
    width: null,
    height: null,
    shouldDraw: false

  });
  const [flag, setFlag] = useState(false);
  const [dataArr, setDataArr] = useState(null);

  const [state, setState] = useState({
    loading:false,
  });

  useEffect(() => {
    loadResult(match.params.id)
  },[match.params.id]);

  useEffect(() => {
    //console.log("dataArr @ useEffect",dataArr);
  },[dataArr]);


  if (document) {
    previmg.src = document;
  }

  if(!resultloading && !flag){
   setDataArr(result);
   setFlag({flag:true});
  }

  const notify = (text, category) => {
    if (category === "success") {
      toast.success(text);
    } else {
      toast.warning(text);
    }
  };

  const handleChange = (e) => {
     e.preventDefault();
    
    let tempDataArr ={...dataArr};
    const JsonResult = JSON.parse(tempDataArr.scannedResult)
    const elementIndex = JsonResult.findIndex(
      (el) => el.regionAttributeKey === e.target.name
    );
    //console.log("elementIndex",elementIndex);
    const newArray = [...JsonResult];
    newArray[elementIndex] = {
      ...newArray[elementIndex],
      regionAttributeValue: e.target.value,
    };
    result.scannedResult = JSON.stringify(newArray)
   // console.log("result after edit",result);
    setDataArr(result);
    updateOldResult(result);
  };

  const drawBox = (e,via_Obj) => {
    let boxDrawings = Object.values(JSON.parse(via_Obj.annotationJson)._via_img_metadata)[0]
      .regions;
    //console.log("boxDrawings",boxDrawings);
    boxDrawings.forEach((item) => {
     // console.log("item.region_attributes.Name",item.region_attributes.Name);
      if (item.region_attributes.Name === e.target.name) {
        setBox({
          ...box,
          x: item.shape_attributes.x,
          y: item.shape_attributes.y,
          width: item.shape_attributes.width,
          height: item.shape_attributes.height,
          shouldDraw: true,
        });
      }
    });
  };

  const save = async (e) => {
    e.preventDefault();
    try {
      let body = {
        templateName: dataArr.templateName,
        newResult : dataArr.scannedResult,
        scannedResultImageName: dataArr.scannedResultImageName,
        templateType: dataArr.TemplateType
      };
      setState({
        ...state,
        loading: true,
      });
     // console.log("body @ save",body);
      // await axios.post("/result/update", body)
      // notify("Result Updated", "success");

      axios.post('/result/update', body)
        .then(function (response) {
          if (response.status == 200) {
            console.log("response",response);
            notify("Result Saved", "success");
            setState({
              ...state,
              loading: false,
            });
            history.push("/results");
            window.location.reload(false);
          }
      })

    } catch (error) {
      console.log(error);
      notify("Result Not Saved");
    }
  };

  const genSourceTypes = () => {
     
      return(
        <>
        <div className="row">
        <div className="col-md-6">
          <div className="documentpreviewdiv">
            <Stage width={500} height={900}>
              <Layer>
                <Image
                  image={previmg}
                  scaleY={scale.y_scale}
                  scaleX={scale.x_scale}
                  offsetX={0}
                  offsetY={0}
                />
                {box.shouldDraw ? (
                  <Rect
                    x={box.x * scale.x_scale}
                    y={box.y * scale.y_scale}
                    width={box.width}
                    height={box.height}
                    stroke="red"
                    shadowBlur={10}
                    scaleX={scale.x_scale}
                    scaleY={scale.y_scale}
                    offsetX={0}
                    offsetY={0}
                  />
                ) : null}
              </Layer>
            </Stage>
          </div>
        </div>
        <div className="col-md-6">
          <div className="righthead">Recognized Values</div>
          <div className="pagedesmaindiv">
            {JSON.parse(dataArr.scannedResult).map((el) => (
              <>
                <div className="pagelabel">{el.regionAttributeKey}</div>
                <div className="anotateinputdiv">
                  <input
                    className="anotateinput"
                    type="text"
                    name={el.regionAttributeKey}
                    value={el.regionAttributeValue || ""}
                    onChange={(e) => handleChange(e)}
                    onFocus={(e) => {
                      drawBox(e,dataArr);
                    }}
                    onBlur={(e) => {
                      setBox({ ...box, shouldDraw: false });
                    }}
                  />
                </div>
              </>
            ))}
          </div>
          <div className="uploadbtndiv">
            <button className="savebtn" 
            onClick={(e) => save(e)}
            >
            {state.loading && <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
            &nbsp;&nbsp;&nbsp;
            {state.loading && <span>Saving</span>}
            {!state.loading && <span>Save</span>}
            </button>
            {result && <CsvDownloader datas={JSON.parse(dataArr.scannedResult)} filename="result">
              <button className="exportbtn">
                Export <i className="fas fa-file-csv"></i>
              </button>
            </CsvDownloader>}
          </div>
        </div>
      </div>
  
        </>
      );
   
    
  };





  //console.log("dataArr",state.dataArr);
  return (
    <Fragment>
      <ToastContainer autoClose={false} />
      {dataArr== null ? (
        <>
          <div className="Sspace"></div>
          <p className="font-weight-bold text-center">
            Your document is loading. Please wait...
          </p>
          <p className="text-center text-danger">
            Do not press refresh or back button
          </p>
          <div className="Sspace"></div>
          <Spinner />
        </>
      ) : (
        <>
          <div className="Sspace"></div>
          <div className="container">
            <button
              type="button"
              className="btn btn-info"
              onClick={history.goBack}
            >
              <i className="fas fa-arrow-left"></i> Back to History
            </button>
          </div>
          <div className="maincontentdiv">
            <div className="container">
              <div className="Sspace"></div>
              <div className="notifytext">
                
              </div>
              <div className="annotatetopheader">
                Result of {dataArr.templateName}
              </div>
              {genSourceTypes()}

             {/* <div className="row">
                <div className="col-md-6">
                  <div className="documentpreviewdiv">
                    <Stage width={500} height={600}>
                      <Layer>
                        <Image
                          image={previmg}
                          scaleY={scale.x_scale}
                          scaleX={scale.y_scale}
                          offsetX={0}
                          offsetY={0}
                        />
                        {box.shouldDraw ? (
                          <Rect
                            x={box.x * scale.x_scale}
                            y={box.y * scale.y_scale}
                            width={box.width}
                            height={box.height}
                            stroke="red"
                            shadowBlur={10}
                            scaleX={scale.x_scale}
                            scaleY={scale.y_scale}
                            offsetX={0}
                            offsetY={0}
                          />
                        ) : null}
                      </Layer>
                    </Stage>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="righthead">Recognized Values</div>
                  <div className="pagedesmaindiv">
                    {JSON.parse(dataArr.scannedResult).map((el) => (
                      <>
                        <div className="pagelabel">{el.regionAttributeKey}</div>
                        <div className="anotateinputdiv">
                          <input
                            className="anotateinput"
                            type="text"
                            name={el.regionAttributeKey}
                            value={el.regionAttributeValue || ""}
                            onChange={(e) => handleChange(e)}
                            onFocus={(e) => {
                              drawBox(e);
                            }}
                            onBlur={(e) => {
                              setBox({ ...box, shouldDraw: false });
                            }}
                          />
                        </div>
                      </>
                    ))}
                  </div>
                  <div className="uploadbtndiv">
                    <button className="savebtn" 
                    onClick={(e) => save(e)}
                    >
                      Save
                    </button>
                    {result && <CsvDownloader datas={JSON.parse(result.scannedResult)} filename="result">
                      <button className="exportbtn">
                        Export <i className="fas fa-file-csv"></i>
                      </button>
                    </CsvDownloader>}
                  </div>
                </div>
                </div>*/}
            </div>
          </div>
        </>
      )}
    </Fragment>
  );
}

Result.propTypes = {
  result: PropTypes.object,
  updateResult: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  data: state.result,
});

export default connect(mapStateToProps, { getDocument, loadResult, updateOldResult })(Result);
