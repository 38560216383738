import React, { Component } from 'react'
import { mailResetPassword } from '../../actions/common/MailResetPasswordAction'
import { connect } from "react-redux";

class MailResetPassword extends Component {

    state = {
        password: '',
        confirmpassword: '',
        errors: {},
        message: "New password and confirm password do not match",
        loginMessage: false,
        // redirect: 0

    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    validatePassword() {
        const errors = {};
        let passwordIsValid = true;
        if (!this.state.password) {
            passwordIsValid = false;
            errors.password = '*Please enter your password.';
        }
        if (typeof this.state.password !== 'undefined') {
            if (!this.state.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)) {
                passwordIsValid = false;
                errors.password = '*Please enter valid password.';
            }
        }
        this.setState({
            errors,
        });
        return passwordIsValid;
    }

    handlePassword = (e) => {
        e.preventDefault();
        console.log("NEW PASSWORD:", this.state.password)
        console.log("CONFIRM PASSWORD:", this.state.confirmpassword)

        if (this.validatePassword()) {
            if (this.state.password === this.state.confirmpassword && this.state.password !== '' && this.state.confirmpassword !== '') {
                // this.props.mailResetPassword({ password: this.state.password, confirmPassword: this.state.confirmpassword, uuid = Id });
                // const customHistory = createBrowserHistory();
                // customHistory.push('/login')
                // localStorage.clear();
                // window.location.href = 'http://localhost:3000';
                let Id = window.location.href
                Id = Id.split('mailresetpassword/')[1];
                // console.log("ID VALUE:", Id)
                // const resetObj = {
                //     UUID: Id,
                //     password: this.state.password,
                // }
                // console.log(resetObj)
                this.props.mailResetPassword({ password: this.state.password, confirmPassword: this.state.confirmpassword, uuid: Id });
                this.setState({ loginMessage: true })
                // setTimeout(function () { //Start the timer
                //     this.setState({ redirect: 1 }) //After 3 second, set render to true
                // }.bind(this), 3000)
                localStorage.clear();
                window.location.href = 'http://localhost:3000';
            }
        }
    }

    // componentDidMount() {
    //     if (this.state.loginMessage === true) {
    //         setTimeout(function () { //Start the timer
    //             this.setState({ redirect: 1 }) //After 3 second, set render to true
    //         }.bind(this), 3000)
    //     }

    // }

    render() {
        return (
            <>
                <div class="Sspace"></div>
                <div class="page-content">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-3">
                            </div>
                            <div class="col-md-9">
                                <div class="page-content-wrapper">
                                    <div class="subheader">
                                        <div class="row">
                                            <div class="col-md-8">
                                                <h1>Reset Your Password</h1>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="Sspace"></div>
                                    <div class="jobsort">
                                        <div class="addjobdiv">
                                            <div class="changepassword">Enter the new password you would like to set for your account.</div>
                                            <div class="row">
                                                <div class="col-md-4">
                                                </div>
                                                <div class="col-md-4">
                                                </div>
                                                <div class="col-md-4">
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="joblabel">New Password <span>*</span></div>
                                                    <div class="jobinputdiv"><input class="jobinputtext" type="password" id="password" name="pass1" value={this.state.password} onChange={this.handleChange} /></div>
                                                    <div className="errorMsg">{this.state.errors.password}</div>
                                                    <div class="changepasswordmust">Must use Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character</div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="joblabel">Confirm Password <span>*</span></div>
                                                    <div class="jobinputdiv"><input class="jobinputtext" type="password" id="confirmpassword" name="confirmpassword" value={this.state.confirmpassword} onChange={this.handleChange} /></div>
                                                    {
                                                        (this.state.password !== this.state.confirmpassword && this.state.password !== '' && this.state.confirmpassword !== '') ? (
                                                            <div className="errorMsg">{this.state.message}</div>
                                                        ) : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div class="Sspace"></div>
                                        <div class="buttonmaindiv">
                                            {this.state.loginMessage === true ? (
                                                <div className="col-md-10">
                                                    <div className="reglabel">You've successfully updated your password.We are taking you to your 'NuOCR' login page now</div>
                                                </div>
                                            ) : (null)}
                                            {/* {this.state.redirect === 1 ? (
                                                window.location.href = "http://localhost:3000/login"
                                            ) : null} */}
                                            <div class="btndiv">
                                                <button class="submitbutton" onClick={this.handlePassword}>Update Password</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="Mspace"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

// export default MailResetPassword

export default connect(null, { mailResetPassword })(MailResetPassword);
