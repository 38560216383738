import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const PrivateRoute = ({
    component: Component,
    auth: { isAuthenticated },
    ...rest
  }) => (
    <Route
      {...rest}
      render={props => {
        if (isAuthenticated===true){
          return <Component {...props} />
        }
        else if(isAuthenticated === false) {
          return <Redirect to="/login" />
        
        }
      }
      }
    ></Route>
  );
  
  PrivateRoute.propTypes = {
    auth: PropTypes.object.isRequired
  };
  
  const mapStateToProps = state => ({
    auth: state.auth
  });
  
  export default connect(mapStateToProps)(PrivateRoute);