import React, { Component } from "react";
import ReactGa from "react-ga";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      errorInfo: null
    };
  }

  componentDidMount() {
    ReactGa.initialize("UA-162538178-1");
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ error, errorInfo:info });
    // // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
    console.log("ERROR CAUGHT WITHIN ERRORBOUNDARY:", error.message);
    ReactGa.exception({
      description: error.message,
      fatal: true,
    });
  }

  render() {
    if (this.state.errorInfo) {
      // You can render any custom fallback UI
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
