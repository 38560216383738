import React, {useEffect,useState} from 'react'
import { Redirect, useHistory } from "react-router-dom";
import axios from "axios";


function CreateTemplate() {

    let history = useHistory();
    const [state, setState] = useState({
      loading:false,
      imgloading:false
    });

    useEffect(() => {
        //console.log("hi welcome pranav");
        const jsondata = localStorage.getItem('JsonBody'  );
        const Bearertoken = localStorage.getItem('token');
       // console.log("token",JSON.parse(Bearertoken).token);
       const imageData =localStorage.getItem('imageData');
       //const data = JSON.stringify(imageData);
      // console.log("imageData",imageData);
       

       
        let axiosConfig = {
          headers: {
            "content-type": "application/json",
            "cache-control": "no-cache",
            "Authorization": `Bearer ${JSON.parse(Bearertoken).token}`
          }
        };
        if(jsondata!=undefined)
        {
        //   axios.post("api/templates/create", jsondata, axiosConfig)
        // .then((res) => {
        //   console.log("RESPONSE RECEIVED: ", res);
        //   localStorage.removeItem('JsonBody');
        //   setState({ ...state, loading: false});
        // })
        // .catch((err) => {
        //   console.log("AXIOS ERROR: ", err);
        // })
         fetch('https://prodnuocrapi.nuvento.com/templates/create', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${JSON.parse(Bearertoken).token}`,
              "cache-control": "no-cache"
            },
            body: jsondata,
          }).then(response => response.json())
            .then(responseJson => {
              console.log(responseJson);
              
            })
            .catch((error) =>{
              console.log(" ERROR: ", error);
            });
        }
        
        if(imageData!=undefined)
        {
        //   axios.post("api/templates/uploadTemplateImage",imageData, axiosConfig)
        // .then((res) => {
        //   console.log("RESPONSE RECEIVED: ", res);
        //   localStorage.removeItem('imageData');
        //   setState({ ...state, imgloading: false});
         
        // })
        // .catch((err) => {
        //   console.log("AXIOS ERROR: ", err);
        // })
        fetch('https://prodnuocrapi.nuvento.com/templates/uploadTemplateImage', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${JSON.parse(Bearertoken).token}`,
            "cache-control": "no-cache"
          },
          body: imageData,
        }).then(response => response.json())
          .then(responseJson => {
            console.log(responseJson);
            
          })
          .catch((error) =>{
            console.log(" ERROR: ", error);
          });
        
        
        }
        
    })

    const btngoback = (e) =>{
      history.push("/ocr");
      window.location.reload(false);
    }

   
    return (

        <>  
            // <Redirect to ='/ocr'></Redirect>
            <div>
            {btngoback}
            </div>
            
        </>
    )
}

export default CreateTemplate;
