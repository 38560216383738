import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
import store from "./store";
import { createBrowserHistory } from "history";
import {logout} from './actions/auth'

import ReactGa from 'react-ga';
import { getUserId } from "./utils/functions";

const customHistory = createBrowserHistory();

console.ignoredYellowBox = ["Warning"];

function initializeAnalytics() {
  ReactGa.initialize("UA-167118937-1");
  ReactGa.pageview(window.location.pathname + window.location.search);
  customHistory.listen((location) => {
    const idVal = getUserId();
    ReactGa.set({ page: `userId:${idVal}/${location.pathname}` }); // Update the user's current page
    ReactGa.pageview(`userId:${idVal}/${location.pathname}`); // Record a pageview for the given page
    // ReactGa.set({ page: location.pathname });
    // ReactGa.pageview(location.pathname);
  })
}
initializeAnalytics();

const notify = (text, category) => {
  if (category === "success") {
    toast.success(text);
  } else {
    toast.error(text)
  }
};

axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;

axios.interceptors.request.use((config) => {
  if (localStorage.token) {
    const token = JSON.parse(localStorage.getItem("token")).token;
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

axios.interceptors.response.use(response => response, error => {
  if (error.response.status === 401) {
    store.dispatch(logout());
    customHistory.push('/login')
    notify("Session expired. Please login again")
  }
  return Promise.reject(error)
})



ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={customHistory}>
      <ToastContainer autoClose={false} />
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
