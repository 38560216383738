import axios from 'axios';

export const MAILRESET_PASSWORD_BEGIN = 'MAILRESET_PASSWORD_BEGIN';
export const MAILRESET_PASSWORD_SUCCESS = 'MAILRESET_PASSWORD_SUCCESS';
export const MAILRESET_PASSWORD_FAILURE = 'MAILRESET_PASSWORD_FAILURE';

export const mailResetPassword = (data) => async dispatch => {
    console.log("data from component in action",data)
    try {
        console.log("testing in action")
        dispatch({
            type: MAILRESET_PASSWORD_BEGIN
        })
        console.log("data in try block",data)
        // const res = await axios.post('/forgotpassword', data);
        const res = await axios.post('/mailresetpassword', {data});
        console.log("response value in action:", res)
        dispatch({
            type: MAILRESET_PASSWORD_SUCCESS,
            payload: res.data
        })
    } catch (err) {
        console.log(err)
        dispatch({
            type: MAILRESET_PASSWORD_FAILURE,
            payload: err.response
        })
    }
}