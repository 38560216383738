import axios from 'axios';

export const CHANGE_PASSWORD_BEGIN = 'CHANGE_PASSWORD_BEGIN';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const changePassword = (data) => async dispatch => {
    console.log("data from component in action",data)
    try {
        console.log("testing in action")
        dispatch({
            type: CHANGE_PASSWORD_BEGIN
        })
        console.log("data in try block",data)
        const res = await axios.post('/changePassword', data);
        // const res = await axios.post('/changePassword', {data});
        console.log("response value in action:", res)
        dispatch({
            type: CHANGE_PASSWORD_SUCCESS,
            payload: res.data
        })
    } catch (err) {
        console.log(err)
        dispatch({
            type: CHANGE_PASSWORD_FAILURE,
            payload: err.response.data
        })
    }
}