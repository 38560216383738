import React from "react";
import PieChart from './PieChart'
// import { connect } from "react-redux";

export const Dashboard = () => {
  return (
    <>
      <div className="maincontentdiv">
        <div className="container">
          <div className="Sspace"></div>
          <div className="topheader2">
            <h2>Dashboard</h2>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="dashmetricsdiv">
                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                  <tbody>
                    <tr>
                      <td width="20%">
                        <div className="metricsicon">
                          <i className="fas fa-chart-bar"></i>
                        </div>
                      </td>
                      <td>
                        <div className="dashmetricvalue">
                          <h2>25</h2>
                          <p>Industry Based Template</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-4">
              <div className="dashmetricsdiv">
                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                  <tbody>
                    <tr>
                      <td width="20%">
                        <div className="metricsicon">
                          <i className="fas fa-chart-bar"></i>
                        </div>
                      </td>
                      <td>
                        <div className="dashmetricvalue">
                          <h2>15</h2>
                          <p>Own Template</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-4">
              <div className="dashmetricsdiv">
                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                  <tbody>
                    <tr>
                      <td width="20%">
                        <div className="metricsicon">
                          <i className="fas fa-chart-bar"></i>
                        </div>
                      </td>
                      <td>
                        <div className="dashmetricvalue">
                          <h2>105</h2>
                          <p>Total No of recognitions</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="Sspace"></div>
          <div className="row">
            <div className="col-md-6">
              <div className="topheader2">
                <h2>Document Recognitions Based on Templates</h2>
              </div>
              <div className="dashboardgraphdiv">Graph Code paste here</div>
            </div>
            <div className="col-md-6">
              <div className="topheader2">
                <h2>Overview of Remaining Recognitions</h2>
              </div>
              <div className="dashboardgraphdiv"><PieChart /></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// const mapStateToProps = (state) => ({

// })

// const mapDispatchToProps = {

// }

export default Dashboard;
// export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
