import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getScanHistory } from "../../actions/result";
import Spinner from '../layout/Spinner'

function RecognitionHistory({ data: { results, historyloading }, getScanHistory }) {
  useEffect(() => {
    getScanHistory();
  },[getScanHistory]);

  const handleDateFormat =  (dateStr) => {
   
    const dt = new Date(dateStr);
    let dd = dt.getDate();
    let mm = dt.getMonth() + 1;
    let yyyy = dt.getFullYear();

    if (dd<10) {
      dd = '0' + dd;
    }
    if (mm<10) {
       mm = '0' + mm;
    }
    return yyyy + '-' + mm + '-' + dd;

  };

  return (
    <>
      <div className="maincontentdiv">
        <div className="container">
          <div className="Sspace"></div>
          <div className="topheader2">
            <h2>Recognition Page</h2>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="rpgriddiv">
                <div className="searchmaindiv">
                  <input
                    type="text"
                    className="searchinput"
                    placeholder="Search "
                  />{" "}
                  <button className="searchbtn">
                    <img src="images/search.svg" alt="" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="rpgriddiv">
            <table width="100%" border="0" cellSpacing="0" cellPadding="0">
              <tbody>
                <tr>
                  <td width="10%" className="gridhead">
                    Sl. No
                  </td>
                  <td className="gridhead">Date</td>
                  <td className="gridhead">Selected Template</td>
                  <td width="15%" className="gridhead" colSpan="2">
                    Action
                  </td>
                </tr>
                {historyloading ? <Spinner /> : results && results.map((item, ind) => (
                  <tr key={item.iD}>
                    <td className="gridexpan1">{ind+1}</td>
                    <td className="gridexpan1">{handleDateFormat(item.createdDateTime)}</td>
                    <td className="gridexpan1">{item.templateName}</td>
                    <td className="gridexpan1">
                      <Link to={`/result/${item.iD}`}>
                        <i className="far fa-file-alt"></i> View
                      </Link>
                    </td>
                    <td className="gridexpan1">
                      <Link to={`/result/${item.iD}`}>
                        <i className="far fa-edit"></i> Edit
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="Sspace"></div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  data: state.result,
});

export default connect(mapStateToProps, { getScanHistory })(RecognitionHistory);
