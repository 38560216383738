import axios from "axios";
export const SCAN = "SCAN";
export const UPDATE_RESULT = "UPDATE_RESULT"
export const TEMPLATE_LOADED = "TEMPLATE_LOADED";
export const SAVE_PDF_BLOB = "SAVE_PDF_BLOB";

export const loadTemplate = (data) => dispatch => {
   // console.log("scan body loadTemplate", data);
    dispatch({
        type: TEMPLATE_LOADED,
        payload: data
    })
}

export const documentScan = (file, template) => async dispatch => {
    try {
        let body = {
           // ...template,
            annotationJson:template,
            base64: file
        }
        
        console.log("scan body document scan", body);
        const res = await axios.post('/recognition/startrec',body)
        console.log("recognition result", res.data);
        dispatch({
            type: SCAN,
            payload: res.data
        })
    } catch (err) {
        console.error(err);
    }

}

export const updateResult = (newData) => dispatch => {
    dispatch({
        type: UPDATE_RESULT,
        payload: newData
    });
}

export const pdfBlobInsert = (data) => async dispatch => {
    try {
        //console.log("scan body pdfBlobInsert", data);
        const res = await axios.post('/recognition/pdfBlobSave',data)
        console.log("response pdfBlobInsert", res);
        dispatch({
            type: SCAN,
            payload: res
        })
    } catch (err) {
        console.error(err);
    }
}
