import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { Stage, Layer, Image, Rect } from "react-konva";
import CsvDownloader from "react-csv-downloader";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../layout/Spinner";
import { updateResult } from "../../actions/scan";
import ReactPaginate from "react-paginate";


const PER_PAGE = 1;

function Result({
  result: { data, loading, template },
  updateResult,
  history, }) {
  const [box, setBox] = useState({
    x: null,
    y: null,
    width: null,
    height: null,
    shouldDraw: false,
  });

  const [currentPage, setCurrentPage] = useState(0);
  const [ResData, setData] = useState({
    res:[],
    NumOfPages:null
  });


  const [state, setState] = useState({
    MutiPage: false,
    loading:false,
  });
  


  useEffect(() => {
    if(template){
      //console.log("template",template.pageCount)
      loadCommentsFromServer();
    }
  },[data]);

  
  function loadCommentsFromServer() {
      if(data){
      console.log("template",template);
    
      let resultObj = [];
      //console.log("currentPage", state.currentPage);
      let arrLen =template.templateData.length;
      //console.log("data", JSON.parse(data[0].result));
      for (let i = 0; i < arrLen; i++) {
        const previmg = new window.Image();
        previmg.src=data[i].bs64;
        
        resultObj.push({
          //"Via_Metadata": template.template[i],
          "Via_Metadata":data[i].template,
          "base64": data[i].bs64,  
          "RecognitionData": data[i].result,
          "previmg":previmg,
        });
      }
      console.log("resultObj",resultObj);
      //console.log("resultObj sorted",resultObj.sort((a, b) => a.index - b.index));
      setData({...ResData,  res:resultObj, NumOfPages:template.pageCount});
      
     
    }
  }
  //console.log("resultObj ResData",ResData.res.RecognitionData[0].length);

  function handlePageClick({ selected: selectedPage }) {
    
    setCurrentPage(selectedPage);
  }

  function SaveAsCSV(data) {
  
    data = data.map(function(obj) { 
      obj['AttributeKey'] = obj['regionAttributeKey']; // Assign new key 
      obj['AttributeValue'] = obj['regionAttributeValue'];
      delete obj['regionAttributeKey'];
      delete obj['regionAttributeValue']; // Delete old key 
      return obj; 
      
    }); 
    //console.log("replaceKeyInObjectArray",data);
    return data;
    
  }

  
   const offset = currentPage * PER_PAGE;
   //console.log("resultObj ResData",ResData.res);
   const currentPageData = ResData.res
     .slice(offset, offset + PER_PAGE)
     .map(( it) => <div className="row">
     <div className="col-md-6">
     <div className="documentpreviewdiv">
       <Stage width={550} height={900}>
         <Layer>
           <Image

             image={it.previmg}
             scaleY={template.imgData.y_scale}
             scaleX={template.imgData.x_scale}
             offsetX={0}
             offsetY={0}
           />
           {box.shouldDraw ? (
             <Rect
               x={box.x * template.imgData.x_scale}
               y={box.y * template.imgData.y_scale}
               width={box.width}
               height={box.height}
               stroke="red"
               shadowBlur={10}
               scaleX={template.imgData.x_scale}
               scaleY={template.imgData.y_scale}
               offsetX={0}
               offsetY={0}
             />
           ) : null}
         </Layer>
       </Stage>
     </div>
   </div>
   <div className="col-md-6">
     <div className="righthead">Recognized Values</div>
     <div className="pagedesmaindiv">
       {JSON.parse(it.RecognitionData).map((el) => (
         <>
           <div className="pagelabel">{el.regionAttributeKey}</div>
           <div className="anotateinputdiv">
             <input
               className="anotateinput"
               type="text"
               name={el.regionAttributeKey}
               value={el.regionAttributeValue || ""}
               onChange={(e) => handleChange(e)}
               onFocus={(e) => {
                 drawBox(e,it.Via_Metadata);
               }}
               onBlur={(e) => {
                 setBox({ ...box, shouldDraw: false });
               }}
             />
           </div>
         </>
       ))}
     </div>

     <div className="uploadbtndiv">
       <button className="savebtn" onClick={(e) => save(e,it.RecognitionData)}>
       {state.loading && <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
        &nbsp;&nbsp;&nbsp;
        {state.loading && <span>Saving</span>}
        {!state.loading && <span>Save</span>}
         
       </button>
      {/*} <CsvDownloader datas={JSON.parse(it.RecognitionData)} filename="result">
         <button className="exportbtn">
           Export <i className="fas fa-file-csv"></i>
         </button>
              </CsvDownloader>*/}
        <CsvDownloader datas={SaveAsCSV(JSON.parse(it.RecognitionData))} filename={template.industryTemplateName}>
          <button className="exportbtn">
            Export <i className="fas fa-file-csv"></i>
          </button>
        </CsvDownloader>
     </div>


   </div>
   </div>
   );

  //  let pageLength=null;
  //  ResData.res.map(item =>{
  //   pageLength=item.RecognitionData[0].length;
  //  })
   //console.log("length",ResData.NumOfPages);
   const pageCount = Math.ceil(ResData.NumOfPages / PER_PAGE);


  const notify = (text, category) => {
    if (category === "success") {
      toast.success(text);
    } else {
      toast.warning(text);
    }
  };




  const handleChange = (e) => {
    e.preventDefault();
    console.log("selected page",currentPage);
    
   // console.log("data to update",ResData.res[currentPage].RecognitionData);
    const JsonResult = JSON.parse(data[currentPage].result)
    console.log("JsonResult",JsonResult);
    console.log("@ handleChange",e.target.name);
    console.log("@ handleChange",e.target.value);
    //console.log("@ handleChange viaData",viaData._via_img_metadata[0].filename);
    
    const elementIndex = JsonResult.findIndex(
      (el) => el.regionAttributeKey === e.target.name
    );

    console.log("@ elementIndex",elementIndex);
    const newArray = [...JSON.parse(data[currentPage].result)]
   
    newArray[elementIndex] = {
      ...newArray[elementIndex],
      regionAttributeValue: e.target.value,
    };

    console.log("newarray",newArray);

    data[currentPage].result = JSON.stringify(newArray)
    console.log("data in update result",data);
    // data=newArray;
    updateResult(data);
    loadCommentsFromServer();
    
    
  };

  const drawBox = (e,via_Obj) => {
    console.log("e",e.target.name);
    //console.log("via obj target name",e.target.name)
    console.log("via obj",via_Obj._via_img_metadata)
    let boxDrawings = Object.values(via_Obj._via_img_metadata)[0]
      .regions;
    console.log("boxDrawings",boxDrawings);
    boxDrawings.forEach((item) => {
      if (item.region_attributes.Name === e.target.name) {
        setBox({
          ...box,
          x: item.shape_attributes.x,
          y: item.shape_attributes.y,
          width: item.shape_attributes.width,
          height: item.shape_attributes.height,
          shouldDraw: true,
        });
      }
    });
  };

  

  const save = async (e,res) => {
    e.preventDefault();
    console.log("res",res);
    try {
      let body = {
        templateName: template.templateName,
        scannedResultImage: template.userFileName,
        base64: ResData.res[currentPage].base64,
        result: JSON.parse(res)
      };
      setState({
        ...state,
        loading: true,
      });
      console.log("body",body);
      if (template.isIndustry) {
        // await axios.post("/result/save", body);
        // notify("Result Saved", "success");
        axios.post('/result/save', body)
        .then(function (response) {
          if (response.status == 200) {
            console.log("response",response);
            notify("Result Saved", "success");
            setState({
              ...state,
              loading: false,
            });
            // history.push("/ocr");
            // window.location.reload(false);
          }
          else{
            notify("Something happened");
          }
          
        })

      } else {
        // await axios.post("/result/saveOwnTemplate", body);
        // notify("Result Saved", "success");

        axios.post('/result/saveOwnTemplate', body)
        .then(function (response) {
          if (response.status == 200) {
            console.log("response",response);
            notify("Result Saved", "success");
            setState({
              ...state,
              loading: false,
            });
            history.push("/ocr");
            window.location.reload(false);
          }
          else{
            notify("Something happened");
          }
          
        })
      }
    } catch (error) {
      console.log(error);
      notify("Result Not Saved");
    }
  };
  const btngoback = (e) =>{
    history.push("/ocr");
    window.location.reload(false);
  }

  return (
    <Fragment>
      <ToastContainer autoClose={false} />
      {loading ? (
        <>
          <div className="Sspace"></div>
          <p className="font-weight-bold text-center">
            Your document is being recognized by NuOCR. Please wait...
          </p>
          <p className="text-center text-danger">
            Do not press refresh or back button
          </p>
          <div className="Sspace"></div>
          <Spinner />
        </>
      ) : (
          <>
            <div className="Sspace"></div>
            <div className="container">
              <button
                type="button"
                className="btn btn-info"
                onClick={btngoback}
              >
                <i className="fas fa-arrow-left"></i> Back to scan
            </button>
            </div>
            <div className="maincontentdiv">
              <div className="container">
                <div className="Sspace"></div>
                <div className="notifytext">
                  Your document has been processed. Edit incorrect values, then
                save or download your file.{" "}
                </div>
                <div className="annotatetopheader">
                  Result of {template.industryTemplateName}
                </div>

                  
                    <div id="react-paginate">
                    {currentPageData}
                    {pageCount!==1 ?(
                      <ReactPaginate
                      previousLabel={"← Previous"}
                      nextLabel={"Next →"}
                      pageCount={pageCount}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      previousLinkClassName={"pagination__link"}
                      nextLinkClassName={"pagination__link"}
                      disabledClassName={"pagination__link--disabled"}
                      activeClassName={"active"}
                    />
                    ):null}
                   
                    {/* <ReactPaginate
                      previousLabel={"Prev"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}/>*/}
                    
                    </div>
                  
              </div>
            </div>
          </>
        )}
    </Fragment>
  );
}

Result.propTypes = {
  result: PropTypes.object,
  updateResult: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  result: state.scan,
});

export default connect(mapStateToProps, { updateResult })(Result);
