import React, { useState } from "react";
import { connect } from "react-redux";
import { login } from "../../actions/auth";
import "./loginStyle.css";
import loadingGif from '../../images/loading.gif'
import { Redirect } from "react-router-dom";
import Event from '../Tracking';

const Login = ({ auth: { isAuthenticated, loading, error }, login }) => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const loginCredentials = {
      userName: formData.username,
      password: formData.password,
    };
    // this.props.dispatch(postUserLogin(loginCredentials, this.props));
    login(loginCredentials);
  };

  if (isAuthenticated) {
    return <Redirect to='/ocr' />
  }

  return (
    <>
      <div>
        <div className="containermaindiv">
          <div className="loginrightdiv">
            <div className="logosmdiv">
              <img src="images/logo1.png" alt="" />
            </div>
            <div className="loginsubdiv">
              {}
              <div className="loginheader">Login</div>
              <form onSubmit={handleSubmit}>
                <div className="username">Username</div>
                <div className="usernametextdiv">
                  <input
                    className="usernametextfield"
                    type="text"
                    id="username"
                    onChange={handleChange}
                    required
                  />{" "}
                  <span>
                    <i className="far fa-user-circle" />
                  </span>
                </div>
                <div className="username">Password</div>
                <div className="usernametextdiv">
                  <input
                    className="usernametextfield"
                    type="password"
                    id="password"
                    onChange={handleChange}
                    required
                  />{" "}
                  <span>
                    <i className="fas fa-unlock-alt" />
                  </span>
                </div>
                <div className="forgotdiv">
                  <span><img src="images/LockLogin.png" width="18" height="18" alt="" /></span>
                  <p onClick={() => Event('login_ForgotPassword', 'Entered Forgot Password Page', '/forgotpassword')}><a href="/forgotpassword">Forgot password?</a></p>
                </div>
                <div className="btnmaindiv">
                  <div className="addnewdiv">
                    <button className="loginbtn" onClick={() => Event('login_Login', 'Login button clicked')}>Login</button>
                  </div>
                  {error ? (
                    <div className="loginerror">
                      Username or password is incorrect
                    </div>
                  ) : (
                      ""
                    )}
                  {/* {this.props.UserToken ? <Link to="/tenantDashboard">Login</Link> : ''} */}
                  {loading ? (
                    <div style={{ padding: "2px 4px" }}>
                      <img src={loadingGif} alt="" height="15" width="15" />
                    </div>
                  ) : (
                      ""
                    )}
                </div>
                <div className="socialicons">
                  <ul>
                    <li>
                      {/* <LinkedIn
                      clientId="81rn9px3u705eu"
                      onFailure={this.handleFailure}
                      onSuccess={this.handleSuccess}
                      redirectUri="http://localhost:3000/linkedin"
                      scope="r_liteprofile+r_emailaddress+w_member_social "
                    >
                      <a
                        href="#"
                        onClick={() => Event('login_linkedIn', 'Entered Linked in login', '/linkedIn')}
                      >
                        <img src={linkedin} alt="" />
                      </a>
                    </LinkedIn> */}
                    </li>
                  </ul>
                </div>

                <div className="Sspace" />
              </form>
              <div className="Sspace" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { login })(Login);
