import axios from "axios";

export const INDUSTRY_TEMPLATE_LOAD = "INDUSTRY_TEMPLATE_LOAD";
export const GET_OWNTEMPLATE_DETAILS_BEGIN = 'GET_OWNTEMPLATE_DETAILS_BEGIN';
export const GET_OWNTEMPLATE_DETAILS_SUCCESS = 'GET_OWNTEMPLATE_DETAILS_SUCCESS';
export const GET_OWNTEMPLATE_DETAILS_FAILURE = 'GET_OWNTEMPLATE_DETAILS_FAILURE';

export const getOwnTemplates = () => async dispatch => {
    try {
        const res = await axios.get('/templates/getowntemplates');
        console.log("own Templates",res.data);
        dispatch({
            type: GET_OWNTEMPLATE_DETAILS_SUCCESS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: GET_OWNTEMPLATE_DETAILS_FAILURE,
            payload: err
        })
    }
}

export const getIndustryTemplate = () => async dispatch => {
    try {
        const res = await axios.get('/templates/getIndustryTemplates');
        dispatch({
            type: INDUSTRY_TEMPLATE_LOAD,
            payload: res.data
        })
    } catch (err) {
        console.error(err);
    }
}