export const generatePassword = () => {
    let length = 8,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
}
    
export const isObjEmpty = (obj)  => {
    if(obj === null) {
        return true
    }
    if (Object.keys(obj).length === 0) {
        return true
    }
    return false
}

const ifTokenExpired = (userToken) => {
  const expiry = Date.parse(userToken.expiration);
  if (expiry > Date.now()) {
    return false;
  }
  return true;
};

export const getUserId = () => {
    const userTkn = JSON.parse(localStorage.getItem("token")) || {};
    const userId = (Object.entries(userTkn).length !== 0 && !ifTokenExpired(userTkn)) ? userTkn.userId : ' ';
    return userId;
  };

