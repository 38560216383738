import {
  INDUSTRY_TEMPLATE_LOAD,
  GET_OWNTEMPLATE_DETAILS_BEGIN,
  GET_OWNTEMPLATE_DETAILS_SUCCESS,
  GET_OWNTEMPLATE_DETAILS_FAILURE,
} from "../actions/template";

const initialState = {
  errorIndustryTemplates: null,
  errorOwnTemplates: null,
  industryTemplates: null,
  ownTemplates: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_OWNTEMPLATE_DETAILS_BEGIN:
      return {
        ...state,
        errorOwnTemplates: null,
      };

    case GET_OWNTEMPLATE_DETAILS_SUCCESS:
      return {
        ...state,
        ownTemplates: action.payload.finalResult,
      };

    case GET_OWNTEMPLATE_DETAILS_FAILURE:
      return {
        ...state,
        errorOwnTemplates: action.payload.error,
      };
    case INDUSTRY_TEMPLATE_LOAD:
      return {
        ...state,
        industryTemplates: payload,
      };
    default:
      return state;
  }
};
