import {
  RESULTS_FETCH_BEGIN,
  RESULTS_FETCH_DONE,
  RESULTS_FETCH_FAILURE,
  RESULT_SELECT,
  RESULT_UPDATE,
  RESULT_UNSELECT,
  FILE_FETCH,
} from "../actions/result";

const initialState = {
  result: null,
  results: null,
  document: null,
  historyloading: true,
  resultloading: true,
  error: null,
  x_scale: null,
  y_scale: null,
};

export default (state=initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case RESULTS_FETCH_BEGIN:
            return {
                ...state,
                historyloading: true,
                resultloading: true,
                error: null
            }
        case RESULTS_FETCH_DONE:
            return {
                ...state,
                historyloading: false,
                results: payload,
                error: null
            }
        case RESULTS_FETCH_FAILURE:
            return {
                ...state,
                error: payload
            }
        case RESULT_SELECT:
            return {
                ...state,
                resultloading: false,
                result: payload
            }
        case RESULT_UPDATE:
            return {
                ...state,
                result: {...state.result, scannedResult: payload}
            }
        case RESULT_UNSELECT:
            return {
                ...state,
                result: null
            }
        case FILE_FETCH:
            return {
                ...state,
                document: payload
            }
        default: return state
    }
}
