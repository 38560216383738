import React, { useEffect, Fragment } from "react";
import { Route, Switch } from "react-router-dom";
// import { setAuthToken, setBaseUrl } from "./utils/setAxiosConfig";
import Navbar from "./components/layout/Navbar";
import NotFound from "./components/layout/NotFound";
import Home from "./components/home/Home";
import Login from "./components/auth/Login";
import CreateTemplate from "./components/template/CreateTemplate";
import EditTemplate from "./components/template/EditTemplate";
import Dashboard from "./components/dashboard/Dashboard";
import Scan from "./components/template/Scan";
import Result from "./components/result/Result";
import Results from "./components/result/Results";
import PrivateRoute from "./components/routing/PrivateRoute";
import ViewResult from "./components/result/viewResult";
import CallViaAPI from "./viajsApiCalls";
import "./App.css";
import ErrorBoundary from "./ErrorBoundary";

//Redux
import store from "./store";
import { loadUser } from "./actions/auth";
import ForgotPassword from "./components/common/ForgotPassword";
import ChangePassword from "./components/common/ChangePassword";
import MailResetPassword from "./components/common/MailResetPassword";
import ApiKey from "./components/common/ApiKey";

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  return (
    <Fragment>
      <Navbar />
      <ErrorBoundary>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/LandingPage" component={Home} />
          <PrivateRoute
            exact
            path="/create-template"
            component={CreateTemplate}
          />
          <PrivateRoute
            exact
            path="/edit-template"
            component={EditTemplate}
          />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/ocr" component={Scan} />
          <PrivateRoute exact path="/result/:id" component={ViewResult} />
          <PrivateRoute exact path="/result" component={Result} />
          <PrivateRoute exact path="/results" component={Results} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <PrivateRoute exact path="/callApi" component={CallViaAPI} />
          <PrivateRoute
            exact
            path="/changepassword"
            component={ChangePassword}
          />
          <Route
            exact
            path="/mailresetpassword/:uuid"
            component={MailResetPassword}
          />
          <PrivateRoute
            exact
            path="/ApiKey"
            component={ApiKey}
          />
          <Route component={NotFound} />
        </Switch>
      </ErrorBoundary>
    </Fragment>
  );
};

export default App;
