import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
// import Footer from "../layout/Footer";
import * as qs from "query-string";
import { resolveToken, activateSubscription } from "../../actions/azure";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// const marketPlaceToken = "1Ce6rVk4PV1QVyUV39O3mlV4UP+qwADh9zJbCIGPNPu815acivEdIpknLc5eIavVeDrT4ZENawrohZGOe1fbe7kg+ETxVkc+NMWUbZ8gtNsdLRBpKbwBUcTbe+KYpHICD+4wCJaYHMINT/LfPoCrXLazMXgRDCYzLxE4uK/gHDHDLm/fk6EVUbRsd4D9IPZzfuxFGof2IihdNLt/e9T5Og=="

const customStyles = {
  content: {
    top: "10%",
    left: "30%",
    right: "30%",
    bottom: "auto",
  },
};

Modal.setAppElement("#root");

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      email: "",
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleActivate = this.handleActivate.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  notify = (text) =>
    toast(text, {
      onOpen: (props) => console.log("onOpen->", props),
      onClose: (props) => console.log("onClose->", props),
      type: toast.TYPE.SUCCESS,
    });

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  handleActivate() {
    this.setState({ showModal: false });
    this.props.activateSubscription(this.props.marketplace.data, this.state.email);
  }

  handleInputChange(e) {
    this.setState({ email: e.target.value });
  }

  componentDidMount() {
    var result = qs.parse(window.location.search);
    console.log(decodeURI(result.token));
    if (result.token) {
      this.props.resolveToken(result.token);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.marketplace.data !== prevProps.marketplace.data) {
      this.handleOpenModal();
    }
    if (this.props.marketplace.activated) {
      this.notify(
        "Congratulations. Your subscription is activated. Please check your email for login details."
      );
      // this.props.history.push('/login')
    }
  }

  render() {
    return (
      <>
        <ToastContainer autoClose={false} />
        <Modal
          isOpen={this.state.showModal}
          onRequestClose={this.handleCloseModal}
          contentLabel="Activation Details"
          style={customStyles}
          shouldCloseOnOverlayClick={false}
        >
          {
            this.props.marketplace.loading ? (
              <></>
            ) : (
              <>
                <p className="font-weight-bold">Activation Details</p>
                <hr />
                <div className="form-group row">
                  <label htmlFor="subName" className="col-sm-4 col-form-label">
                    Subscription Name
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control-plaintext"
                      id="subName"
                      value={
                        this.props.marketplace.data.SubscriptionDetail.subscriptionName
                      }
                      readOnly
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="offerId" className="col-sm-4 col-form-label">
                    Offer Id
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control-plaintext"
                      id="offerId"
                      value={this.props.marketplace.data.SubscriptionDetail.offerId}
                      readOnly
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="planId" className="col-sm-4 col-form-label">
                    Plan Id
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control-plaintext"
                      id="planId"
                      value={this.props.marketplace.data.SubscriptionDetail.planId}
                      readOnly
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="inputEmail"
                    className="col-sm-4 col-form-label"
                  >
                    Email
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="email"
                      className="form-control"
                      id="inputEmail"
                      value={this.state.email}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-4">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={this.handleActivate}
                    >
                      Activate
                    </button>
                  </div>
                </div>
              </>
            )
          }
        </Modal>
        <div className="wrapper-container">
          <div className="container">
            <div className="topheadertext">
              <h2>Welcome to NuOCR</h2>
            </div>
          </div>
        </div>
        <div className="main-content">
          <div className="container">
            <div className="Sspace"></div>
            <div className="topheader1">
              <h2>
                <span>NuOCR</span>
              </h2>
              <div className="row">
                <div className="col-md-12">
                  <p>
                    The only data extraction solution you’ll need
                    <br />
                    Automate data extraction. Transform to digital files. Save
                    the documents to database. Reduce costs and maximize
                    productivity.
                  </p>
                </div>
              </div>
              <div className="Sspace"></div>
              <div className="row">
                <div className="col-md-3">
                  <img src="images/file.svg" alt="" />
                  Convert documents and forms into searchable and editable
                  format.
                </div>

                <div className="col-md-3">
                  <img src="images/edit-tools.svg" alt="" />
                  Improve data accuracy with digital documents.
                </div>
                <div className="col-md-3">
                  <img src="images/file1.svg" alt="" />
                  Increase storage space with lesser paper documents.
                </div>
                <div className="col-md-3">
                  <img src="images/hands-and-gestures.svg" alt="" />
                  Save costs and improve service efficiencies.
                </div>
              </div>
            </div>
            <div className="Sspace"></div>
            <div className="Sspace"></div>
          </div>
        </div>
        <div className="bottomcomingsoondiv">
          <div className="container">
            <div className="bottomcontent">
              <div className="topheader">
                <h2>
                  Coming Soon
                  <br />
                  Redefine the functionality of your <span>NuOCR </span>system
                  with these upcoming features.
                </h2>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="groupmaindiv hvrbox">
                    <div className="groupimgdiv">
                      <img src="images/slider-3-small.jpg" alt="" />
                      <div className="groupnewheading">
                        <h3>
                          <a href="/#">Advanced Search </a>
                        </h3>
                      </div>
                    </div>
                    <div className="hvrbox-layer_top hvrbox-layer_slideup">
                      <div className="grouptextdiv hvrbox-text">
                        <p>
                          Coming soon
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="groupmaindiv hvrbox">
                    <div className="groupimgdiv">
                      <img src="images/presentation-3.jpg" alt="" />
                      <div className="groupnewheading">
                        <h3>
                          <a href="/#">
                            Integration with cloud document repositories{" "}
                          </a>
                        </h3>
                      </div>
                    </div>
                    <div className="hvrbox-layer_top hvrbox-layer_slideup">
                      <div className="grouptextdiv hvrbox-text">
                        <p>
                          Coming soon
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="groupmaindiv hvrbox">
                    <div className="groupimgdiv">
                      <div className="groupnewheading">
                        <h3>
                          <a href="/#">Multipage Templates </a>
                        </h3>
                      </div>
                      <img src="images/portfolio-course-updated.jpg" alt="" />
                    </div>
                    <div className="hvrbox-layer_top hvrbox-layer_slideup">
                      <div className="grouptextdiv hvrbox-text">
                        <p>
                          Coming soon
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="groupmaindiv hvrbox">
                    <div className="groupimgdiv">
                      <img src="images/Network-Conseillers.jpg" alt="" />
                      <div className="groupnewheading">
                        <h3>
                          <a href="/#">API Integration </a>
                        </h3>
                      </div>
                    </div>
                    <div className="hvrbox-layer_top hvrbox-layer_slideup">
                      <div className="grouptextdiv hvrbox-text">
                        <p>
                          Coming soon
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="groupmaindiv hvrbox">
                    <div className="groupimgdiv">
                      <img src="images/relax.jpg" alt="" />
                      <div className="groupnewheading">
                        <h3>
                          <a href="/#">More templates </a>
                        </h3>
                      </div>
                    </div>
                    <div className="hvrbox-layer_top hvrbox-layer_slideup">
                      <div className="grouptextdiv hvrbox-text">
                        <p>
                          Coming soon
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="groupmaindiv hvrbox">
                    <div className="groupimgdiv">
                      <img src="images/learn.jpg" alt="" />
                      <div className="groupnewheading">
                        <h3>
                          <a href="/#">Add your own users </a>
                        </h3>
                      </div>
                    </div>
                    <div className="hvrbox-layer_top hvrbox-layer_slideup">
                      <div className="grouptextdiv hvrbox-text">
                        <p>
                          Coming soon
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="groupmaindiv hvrbox">
                    <div className="groupimgdiv">
                      <img src="images/elearning.jpg" alt="" />
                      <div className="groupnewheading">
                        <h3>
                          <a href="/#">Add your own roles </a>
                        </h3>
                      </div>
                    </div>
                    <div className="hvrbox-layer_top hvrbox-layer_slideup">
                      <div className="grouptextdiv hvrbox-text">
                        <p>
                          Coming soon
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Sspace"></div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  marketplace: state.marketplace,
});

export default connect(mapStateToProps, { resolveToken, activateSubscription })(
  Home
);
