
export const DATASOURCE_CONFIG_ADDED = "DATASOURCE_CONFIG_ADDED";
export const USER_API_KEY_GENERATE_BEGIN = "USER_API_KEY_GENERATE_BEGIN";
export const USER_API_KEY_GENERATE_SUCCESS = "USER_API_KEY_GENERATE_SUCCESS";
export const USER_API_KEY_GENERATE_FAILED = "USER_API_KEY_GENERATE_FAILED";
export const USER_API_KEY_REMOVE_BEGIN = "USER_API_KEY_REMOVE_BEGIN";
export const USER_API_KEY_REMOVE_SUCCESS = "USER_API_KEY_REMOVE_SUCCESS";
export const USER_API_KEY_REMOVE_FAILED = "USER_API_KEY_REMOVE_FAILED";


