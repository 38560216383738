import React, { useState, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { documentScan, loadTemplate, pdfBlobInsert } from "../../actions/scan";
import { getOwnTemplates, getIndustryTemplate } from "../../actions/template";
import { isObjEmpty } from "../../utils/functions";
import Select from "react-select";
import Event from "../Tracking";
import axios from "axios";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { ToastContainer, toast } from "react-toastify";

//Template Tooltip Description
// const templateTooltip = "Sample document that has already some details in place regarding the layout"

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const industryTemplates = [
  { label: "Tax", value: "Tax" },
  { label: "Social Security", value: "SocialSecurity" },
  { label: "Legal", value: "Legal" },
  { label: "Insurance", value: "Insurance" },
  { label: "Human resources", value: "Human resources" },
  { label: "Education", value: "Education" },
  { label: "Health Care", value: "Health Care" },
  { label: "Real Estate", value: "Real Estate" },
];

const Scan = (props) => {
  const history = useHistory();

  const [state, setState] = useState({
    ownTemplates: [],
    isIndustrySelected: false,
    industry: null,
    currentIndustryTemplates: null,
    template: null,
    prevImgSource: null,
    uploadedFile: null,
    industryTemplateName: null,
    templateName: null,
    height: null,
    width: null,
    typeofFile: null,
    imgSasUrl: null,
    pdfImageB64: null,
    imgLoaded: false,
    imgUploadLoaded: true,
    multipageData: [],
    selectedTemp:false,
    loading:false
  });

  const [ownTemplateChecked, setOwnTemplateChecked] = useState(false);

  const { getOwnTemplates, getIndustryTemplate } = props;

  let owntemplate = localStorage.getItem("owntemplate");
  if (owntemplate !== null) {
    setState({ ...state, template: owntemplate, prevImgSource: owntemplate });
    localStorage.removeItem("owntemplate");
  }

  useEffect(() => {
    getOwnTemplates();
    getIndustryTemplate();
  }, [getIndustryTemplate, getOwnTemplates]);

  const getSubInd = (ind) => {
    switch (ind) {
      case "own templates":
        return props.ownTemplateDetails.map((item) => {
          return {
            label: item.blobName.split("/")[2],
            value: item.imgUrl,
          };
        });

      default:
        return props.industryTemplates[ind].map((it) => {
          //console.log("it",it);
          return {
            label: it.label,
            value: it.pages[0].value,
          };
        });
    }
  };

  const handleIndChange = (selectedOption) => {
    setState({
      ...state,
      isIndustrySelected: true,
      industry: selectedOption.value,
    });
  };

  const handleIndTemplateChange = async (selectedOption) => {
    const annotationArr = [];
    let template_json = props.industryTemplates[state.industry].filter(
      (item) => item.pages[0].value === selectedOption.value
    );
    //console.log("template_json",template_json);
    const annotationObj = template_json[0].pages;
    console.log("annotationObj", annotationObj);
    annotationObj.map(item => {

      annotationArr.push(item.annotationJson);
    });
    console.log("annotationJson Mapped", annotationArr);

    setState({
      ...state,
      //template: template_json[0].pages[0].annotationJson,
      template: annotationArr,
      prevImgSource: selectedOption.value,
      industryTemplateName: `${state.industry}/${selectedOption.label}`,
      templateName: selectedOption.label,
    });
    // console.log("state data handleIndTemplateChange ",state.template,
    // "prevImgSource",state.prevImgSource,"industryTemplateName",state.industryTemplateName,
    // "templateName",state.templateName);
  };

  const handleOwnTemplateChange = async (selectedOption) => {
    console.log("selected option",selectedOption);

    const annotationArr = [];
    let template_json = props.ownTemplateDetails.filter(
      (item) => item.imgUrl === selectedOption.value
    );
    annotationArr.push(template_json[0].annotationJson);
    setState({
      ...state,
      template: annotationArr,
      prevImgSource: selectedOption.value,
      isIndustrySelected: false,
      industry: null,
      industryTemplateName: selectedOption.label,
      templateName: selectedOption.label,
      selectedTemp:true

    });

    
  };

  
  const handleDelete = () => {
    console.log("template selected", state.templateName);
    setState({
      ...state,
      loading: true,
    });
    const data = {
      fileName: state.templateName,
    };
    axios
    .post("/templates/DeleteTemplate", data)
    .then(function (response) {
      if (response.status == 200) {
        console.log("res from axios pdfBlobSave ",response.data[0].rowsupdated);
        notify("Template deleted", "success");
        setState({
          ...state,
          loading: false,
        });
        window.location.reload(false);
      }
      else{
        notify("Something happened");
      }
    })
    .catch(function (error) {
      console.log("Error in axios", error);
    });
  };

  const notify = (text, category) => {
    if (category === "success") {
      toast.success(text);
    } else {
      toast.warning(text);
    }
  };

  const handleEdit = async () => {
    localStorage.setItem("selectedImage", state.prevImgSource);
    var url = state.prevImgSource

    await fetch('https://nuocrstracc.blob.core.windows.net/nuocrtemplates/OWnTemplates/62ac240a-f44e-42d8-b09d-dc7a9e14ed91/OCR_project_10Dec2020_12h7m?st=2020-12-11T04%3A05%3A19Z&se=2020-12-11T07%3A25%3A19Z&sp=r&sv=2018-03-28&sr=b&sig=q%2Bzu4N%2BxYU4waLXrK5vi6BA2gckKMQO3CsRmjk8uJqQ%3D').then(function(response) {
      return response.blob();
    }).then(function(myBlob) {
     console.log("myBlob",myBlob)
    });

  };


  const handleFileChoice = async (e) => {
    const { uploadedFile } = state;
    if (e.target.files[0] != null) {
      setState({
        ...state,
        imgUploadLoaded: false,
      });
      const file = URL.createObjectURL(e.target.files[0]);
      // console.log("FILE-->", file);
      // console.log("e.target.files[0]", e.target.files[0]);
      const type = e.target.files[0].type;
      const filename = e.target.files[0].name;
      const fileuploaded = e.target.files[0];
      if (type == "application/pdf") {
        // setState({ ...state, typeofFile: type, uploadedFile:fileuploaded });
        // console.log("uploadedFile if pdf",state.uploadedFile);
        const uploadedFileBase64 = await toBase64(e.target.files[0]);
        const data = {
          Pdfbase64: uploadedFileBase64,
          fileType: type,
          fileName: filename,
        };
        // console.log("data", data);
        // console.log("state objects", fileuploaded);
        try {
          axios
            .post("/recognition/pdfBlobSave", data)
            .then(function (response) {
              if (response.status == 200) {

                console.log(
                  "res from axios pdfBlobSave ",
                  response.data
                );

                setState({
                  ...state,
                  imgSasUrl: response.data.imgSasUrl,
                  pdfImageB64: response.data.base64,
                  imgLoaded: true,
                  typeofFile: type,
                  uploadedFile: fileuploaded,
                  imgUploadLoaded: true,
                  prevImgSource: response.data[0].imgSasUrl,
                  multipageData: response.data
                });
              }
            })
            .catch(function (error) {
              console.log("Error in axios", error);
            });
        } catch (err) {
          console.error(err);
        }
      } else {
        // console.log("image type", type);
        setState({
          ...state,
          prevImgSource: file,
          uploadedFile: e.target.files[0],
          imgUploadLoaded: true,
        });
      }
    }
  };

  // Unused function
  const handleUpload = () => {
    console.log("File Uploaded");
  };

  const handleClick = (e) => {
    // console.log(e.target.value);
    switch (e.target.value) {
      case "own":
        setOwnTemplateChecked(true);
        break;
      case "ind":
        setOwnTemplateChecked(false);
        break;
      default:
        setOwnTemplateChecked(false);
    }
  };

  const handleScan = async (e) => {
    e.preventDefault();
    const {
      uploadedFile,
      template,
      industryTemplateName,
      templateName,
      typeofFile,
      multipageData,
    } = state;

    if (typeofFile == "application/pdf" && multipageData != null) {
      try {

        const bs64Arr = [];
        let userFileName = uploadedFile.name;
        let pdfImgB64 = "";
        let pdfImageArr = [];


        multipageData.map((item) => {
          pdfImgB64 = item.base64
          const b64 = pdfImgB64.split(",")[1];
          bs64Arr.push(b64);
          pdfImageArr.push(pdfImgB64);
        });
        props.documentScan(bs64Arr, template);
        let pageCount = pdfImageArr.length;
        //console.log("pdfImageArr",pdfImageArr.length)
        if (state.isIndustrySelected) {
          let data = {
            templateData: pdfImageArr,
            industryTemplateName,
            templateName,
            imgData: { y_scale: 700 / state.height, x_scale: 700 / state.width },
            // template:template[0],
            template,
            isIndustry: state.isIndustrySelected,
            userFileName,
            pageCount
          }
          console.log("pdf", data)
          props.loadTemplate(data);
        }
        else {
          let data = {
            templateData: pdfImageArr,
            isIndustry: state.isIndustrySelected,
            industryTemplateName: templateName,
            templateName,
            imgData: { y_scale: 700 / state.height, x_scale: 700 / state.width },
            userFileName,
            pageCount
          }
          props.loadTemplate(data);
        }
        history.push('/result')

      }
      catch (err) {
        console.error(err);
      }
    }
    else {

      try {
        // console.log("uploadedFile", uploadedFile);
        const pageCount = 1;
        const bs64Arr = [];
        let ImageArr = [];
        let b64Image = await toBase64(uploadedFile);
        let userFileName = uploadedFile.name;
        const b64 = b64Image.split(",")[1];
        bs64Arr.push(b64);
        ImageArr.push(b64Image);
        // console.log("b64", b64);
        props.documentScan(bs64Arr, template);
        if (state.isIndustrySelected) {
          let data = {
            templateData: ImageArr,
            industryTemplateName,
            templateName,
            imgData: {
              y_scale: 700 / state.height,
              x_scale: 700 / state.width,
            },
            template,
            isIndustry: state.isIndustrySelected,
            userFileName,
            pageCount
          };
          props.loadTemplate(data);
        } else {
          let data = {
            templateData: ImageArr,
            isIndustry: state.isIndustrySelected,
            industryTemplateName: templateName,
            templateName,
            imgData: {
              y_scale: 700 / state.height,
              x_scale: 700 / state.width,
            },
            userFileName,
            pageCount
          };
          props.loadTemplate(data);
        }
        history.push("/result");
      } catch (err) {
        console.error(err);
      }
    }
  };

  const getMeta = (url, callback) => {
    var img = new Image();
    img.src = url;
    img.onload = function () {
      callback(this.width, this.height);
    };
  };

  const onImgLoad = ({ target: img }) => {
    //console.log("height",img.naturalHeight,"width",img.naturalWidth);
    setState({
      ...state,
      height: img.naturalWidth,
      width: img.naturalHeight,
    });
  };

  

  return (
    <>
      <>
        <div className="maincontentdiv">
          <div className="container">
            <div className="Sspace"></div>
            {/* <div className="selctempbtndiv">
              <Link className="selecttemplatebtn" to="/createtemplate">
                Build Your Own Template
              </Link>
            </div> */}
            <div className="row">
              <div className="col-md-6">
                <div className="searchmaindiv">
                  <input
                    type="text"
                    className="searchinput"
                    placeholder="Search Template"
                    onClick={() =>
                      Event(
                        "TemplateSelection_Search",
                        "Search Template clicked"
                      )
                    }
                  />{" "}
                  <button className="searchbtn">
                    <img src="images/search.svg" alt="" />
                  </button>
                </div>
                <div className="Sspace"></div>
                <div className="notifytext">
                  Choose a document template from our list or create your own.
                  Upload filled-out document.
                </div>
                {/* <div className="row">
                  <div className="col-sm-4"><label title={templateTooltip}>Own template?</label></div>
                  <div className="col=sm-2" ><input type="checkbox" checked={ownTemplateChecked} onClick={handleClick} /></div>
                </div> */}

                <div className="row">
                  <div className="col-md-12">
                    <div className="tabset">
                      <input
                        type="radio"
                        name="tabset"
                        value="ind"
                        id="tab1"
                        aria-controls="marzen"
                        checked={!ownTemplateChecked}
                        onChange={handleClick}
                        onClick={() =>
                          Event(
                            "TemplateSelection_Pre-Build",
                            "Pre-Build Templates clicked"
                          )
                        }
                      />
                      <label htmlFor="tab1">Pre-built Templates</label>

                      <input
                        type="radio"
                        name="tabset"
                        value="own"
                        id="tab2"
                        aria-controls="rauchbier"
                        checked={ownTemplateChecked}
                        onChange={handleClick}
                        onClick={() =>
                          Event(
                            "TemplateSelection_Own",
                            "Own Templates clicked"
                          )
                        }
                      />
                      <label htmlFor="tab2">Own Templates</label>

                      <div className="tab-panels">
                        <section id="marzen" className="tab-panel">
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="industrymaindiv">
                                <div className="industrylabel">
                                  Choose from Pre-built Template
                                </div>
                                <div
                                  className="industrydiv"
                                  onClick={() =>
                                    Event(
                                      "TemplateSelectionPre-build_Select",
                                      "Pre-Build Select Dropdown clicked"
                                    )
                                  }
                                >
                                  <Select
                                    classNamePrefix="react-select"
                                    options={industryTemplates}
                                    onChange={handleIndChange}
                                    isDisabled={isObjEmpty(
                                      props.industryTemplates
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                            {state.industry ? (
                              <div className="col-sm-6">
                                <div className="industrymaindiv">
                                  <div className="industrylabel">
                                    {state.industry || "Sub"} Templates
                                  </div>
                                  <div className="industrydiv">
                                    {/*@TODO css */}
                                    <Select
                                      classNamePrefix="react-select"
                                      options={getSubInd(state.industry)}
                                      onChange={handleIndTemplateChange}
                                      onClick={() =>
                                        Event(
                                          "TemplateSelectionOwn_Select",
                                          "Own Select Dropdown clicked"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </section>
                        <section id="rauchbier" className="tab-panel">
                          <div className="selctempbtndiv">
                            <button className="selecttemplatebtn">
                              <Link
                                className="selecttemplatebtn"
                                to="/create-template"
                                onClick={() =>
                                  Event(
                                    "TemplateSelectionOwn_Build",
                                    "Build Your Own Template clicked"
                                  )
                                }
                              >
                                Build Your Own Template
                              </Link>
                            </button>
                          </div>

                          <div className="row">

                            <div className="col-md-12">
                              <div className="industrymaindiv">
                                <div className="industrylabel">
                                  Own Template
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="industrydiv">
                                      <Select
                                        classNamePrefix="react-select"
                                        options={getSubInd("own templates")}
                                        isDisabled={isObjEmpty(
                                          props.ownTemplateDetails
                                        )}
                                        onChange={handleOwnTemplateChange}
                                      />
                                    </div>
                                  </div>

                                  {
                                    state.selectedTemp ? (
                                    <div className="col-md-6">
                                    <div className="row">
                                      <div>
                                        <button
                                          id="editBtn"
                                          className="optionBtn"
                                          //disabled={!(this.state.sourceType === item)}
                                          onClick={handleDelete}
                                        >
                                        {state.loading && <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                                        &nbsp;&nbsp;&nbsp;
                                        {state.loading && <span>Deleting</span>}
                                        {!state.loading && <span>Delete</span>}
                                          
                                        </button>
                                      </div>
                                      {/*<div>
                                        <button className="optionBtn">
                                          <Link
                                            //className="selecttemplatebtn"
                                            to="/edit-template"
                                            // onClick={() =>
                                            //   Event(
                                            //     "TemplateSelectionOwn_Build",
                                            //     "Build Your Own Template clicked"
                                            //   )
                                            // }
                                            onClick={handleEdit}
                                          >
                                          Edit
                                          </Link>
                                        

                                       
                                          
                                        </button>
                                      </div>*/}
                                    </div>
                                    </div>
                                    
                                    ):null
                                  }
                                  
                                </div>

                              </div>
                            </div>


                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                  {state.template ? (
                    <div className="col-sm-12 ">
                      <div className="SSspace"></div>
                      <div className="industrymaindiv">
                        <div className="industrydiv">
                          <input
                            className="NuOcrfile"
                            type="file"
                            onChange={handleFileChoice}
                          />
                        </div>
                        <span>{!state.imgUploadLoaded ? <p>File uploading please wait...</p> : null}</span>
                      </div>
                    </div>
                  ) : (
                      <Fragment></Fragment>
                    )}
                </div>

                {/* {state.template ? (
                  <div className="uploadbtndiv">
                    <button className="uploadbtn" onClick={handleUpload}>
                      Upload
                    </button>
                  </div>
                ) : (
                  <Fragment></Fragment>
                )} */}
              </div>
              <div className="col-md-6">
                <div className="previewmaindiv">
                  {!state.imgUploadLoaded ? (
                    <div className="preLoadimg">
                      <SkeletonTheme>
                        <h1>
                          <Skeleton count={1} width={150} />
                        </h1>
                        <p>
                          <Skeleton count={4} />
                        </p>
                        <br />
                        <p>
                          <Skeleton />
                          <Skeleton width={250} />
                          <Skeleton />
                        </p>
                      </SkeletonTheme>
                    </div>
                  ) : state.typeofFile === "application/pdf" ? (
                    (
                      (
                        <div className="Loadedimg">
                          {state.imgLoaded ? (
                            <img
                              className="previewimgloaddiv"
                              src={state.prevImgSource}
                              onLoad={onImgLoad}
                              href="#"
                              alt="Loading..."
                            />
                          ) : (
                              <div className="preLoadimg">
                                <SkeletonTheme >
                                  <p>
                                    <Skeleton count={6} />
                                  </p>
                                </SkeletonTheme>
                              </div>
                            )}
                        </div>
                      ))
                  ) : (
                        <div className="previewimgload">
                          {state.prevImgSource ? (
                            (
                              (
                                <img
                                  className="Loadedimg"
                                  src={state.prevImgSource}
                                  onLoad={onImgLoad}
                                  href="#"
                                  alt="Loading..."
                                />
                              ))
                          ) : (
                              <div className="previewimgloaddiv">&nbsp;</div>
                            )}
                        </div>
                      )}
                  {/*<div className="previewimgload">
                    {state.prevImgSource ? (
                      <img
                        className="previewimgloaddiv"
                        src={state.prevImgSource}
                        onLoad={onImgLoad}
                        href="#"
                        alt="Broken"
                      />
                    ) : (
                        <div className="previewimgloaddiv">&nbsp;</div>
                      )}
                    </div> */}
                </div>
                {state.uploadedFile
                  ? (
                    (
                      <div className="uploadbtndiv">
                        <button
                          className="startrecogbtn col-md-6"
                          onClick={(e) => handleScan(e)}
                        >
                          <img src="images/safety.svg" alt="" /> &nbsp; &nbsp;
                          Start Recognition
                        </button>
                      </div>
                    ))
                  : (
                    (<Fragment></Fragment>))}
              </div>
            </div>
          </div>
        </div>
      </>
      {/* <Footer /> */}
    </>
  );
};

// TemplateSelection.propTypes = {

// }
const mapStateToProps = (state) => ({
  ownTemplateDetails: state.template.ownTemplates,
  industryTemplates: state.template.industryTemplates,
  convertedPdfData: state.scan.convertedPdfData,
});

export default connect(mapStateToProps, {
  documentScan,
  getOwnTemplates,
  getIndustryTemplate,
  loadTemplate,
  pdfBlobInsert,
})(Scan);
